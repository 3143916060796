import React from 'react';
const Dashboard = React.lazy(() => import('../containers/dashboard'));
const Tests = React.lazy(() => import('../containers/tests'));
const Lessons = React.lazy(() => import('../containers/lessons'));
const Courses = React.lazy(() => import('../containers/courses'));
const Topics = React.lazy(() => import('../containers/topics'));
const LessonInformation = React.lazy(() => import('../containers/lessonInformation'));
const UserSolvedSummary = React.lazy(() => import('../containers/lessons/userSolvedSummary'));
const UserSolvedQuestion = React.lazy(() => import('../containers/question/userSolvedQuestion'));
const LessonsDetail = React.lazy(() => import('../containers/lessons/detail'));
const Statistics = React.lazy(() => import('../containers/statistics'));
const WhatOthersDo = React.lazy(() => import('../containers/whatOthersDo'));
const TrialExams = React.lazy(() => import('../containers/trialExam/trialExam'));
const TrialExamDetail = React.lazy(() => import('../containers/trialDetail'));
const TrialExamTest = React.lazy(() => import('../containers/trialExamTest'));
const TrialExamResult = React.lazy(() => import('../containers/trialExamResult'));
const OnlineTrialExams = React.lazy(() => import('../containers/onlineTrialExams'));
const MyDuties = React.lazy(() => import('../containers/myDuties'));
const Publisher = React.lazy(() => import('../containers/publisher'));
const MyProfile = React.lazy(() => import('../containers/myProfile'));
const ResetPassword = React.lazy(() => import('../containers/myProfile/resetPassword'));
const InviteYourFriends = React.lazy(() => import('../containers/inviteYourFriends'));
const Test = React.lazy(() => import('../containers/test'));
const FlashCard = React.lazy(() => import('../containers/flashCard'));
const AddFromOutside = React.lazy(() => import('../containers/addFromOutside'));
const PdfIntroduction = React.lazy(() => import('../containers/pdfIntroduction'));
const PdfReader = React.lazy(() => import('../containers/pdfReader'));
const PdfSendAnswers = React.lazy(() => import('../containers/pdfSendAnswers'));
const TestResult = React.lazy(() => import('../containers/test/testResult'));
const KttTestResult = React.lazy(() => import('../containers/test/kttTestResult'));
const TrialTestResult = React.lazy(() => import('../containers/test/trialTestResult'));
const TrialAnalysis = React.lazy(() => import('../containers/test/trialTestResult/trialAnalysis'));
const PartialTrialTestResult = React.lazy(() => import('../containers/test/partialTrialTestResult'));
const TestInformation = React.lazy(() => import('../containers/testInformation'));
const Lottery = React.lazy(() => import('../containers/lottery'));
const TestCredit = React.lazy(() => import('../containers/testCredit'));
const Simulator = React.lazy(() => import('../containers/simulator'));
const SimulatorForm = React.lazy(() => import('../containers/simulator-form'));
const ViewInformation = React.lazy(() => import('../containers/viewInformation'));
const Logout = React.lazy(() => import('../containers/logout'));
const onlineLecture = React.lazy(() => import('../containers/onlineLecture'));
const TestIstatistiks = React.lazy(() => import('../containers/testIstatistiks'));
const watchAll = React.lazy(() => import('../containers/onlineLectureWatchAll'));
const videoLesson = React.lazy(()=>import('../containers/lessons/lessonVideos'))
const TopicScanTest = React.lazy(()=> import('../containers/topicScanTest/TopicScanTest'))
//const schoolPanel = React.lazy(() => import('../containers/schoolPanel'));
const ChatGpt = React.lazy(() => import('../containers/ChatGptPrompt'));

const schoolPanel = React.lazy(() => import('../containers/schoolPanel/createTags/index'));
const schoolPanelLectures = React.lazy(() => import('../containers/schoolPanel/lectures'));
const schoolPanelStatistics = React.lazy(() => import('../containers/schoolPanel/statistics'));
const TrialPdfSendAnswers = React.lazy(() => import('../containers/trialPdfSendAnswers'));

const ScoreCalculation = React.lazy(() => import('../containers/admission/scoreCalculation'));
const Universities = React.lazy(() => import('../containers/admission/universities'));
const MyPreferenceList = React.lazy(() => import('../containers/admission/myPreferenceList'));
const MyResults = React.lazy(() => import('../containers/admission/myResults'));
const CorporateCode = React.lazy(()=>import('../containers/CorporateCode'))
const PdfCodeInput = React.lazy(()=>import('../containers/getPdfFromCode'))

const Coaching = React.lazy(()=>import('../containers/coaching'))
const WhatIsCoaching = React.lazy(()=>import('../containers/whatIsCoaching'))
const YKSJourney = React.lazy(()=>import('../containers/yksJourney'))

const Introduction = React.lazy(()=>import('../containers/introduction'))

const StudyHistory = React.lazy(()=>import('../containers/studyHistory'))
const SchoolPanelHomeworks = React.lazy(()=>import('../containers/schoolPanel/homeworks'))
const SchoolPanelHomeworkInfo = React.lazy(()=>import('../containers/schoolPanel/homeworkInfo'))

const AchievementTest = React.lazy(()=>import('../containers/achievementTest'))

const privateRoute = [
	{
		path: '/',
		name: 'Anasayfa',
		component: Dashboard,
		layout: 'dashboard',
	},
	{
		path: '/testlerim',
		name: 'Testler',
		component: Tests,
		layout: 'dashboard',
	},
	{
		path: '/dersler',
		name: 'Dersler',
		component: Courses,
		layout: 'dashboard',
	},
	{
		path: '/denemeler',
		name: 'Denemeler',
		component: TrialExams,
		layout: 'dashboard',
	},
	{
		path: '/denemeler/detay',
		name: 'DenemelerDetay',
		component: TrialExamDetail,
		layout: 'dashboard',
	},
	{
		path: '/denemeler/test/:id/:isShowAnswers',
		name: 'DenemelerTest',
		component: TrialExamTest,
		layout: 'dashboard',
	},
	{
		path: '/denemeler/test-sonucu/:id/:title',
		name: 'DenemelerSonuc',
		component: TrialExamResult,
		layout: 'dashboard',
	},
	{
		path: '/dersler-sayfasında-neler-var',
		name: 'Derslesr Sayfasında Neler Var?',
		component: LessonInformation,
		layout: 'dashboard',
	},
	{
		path: '/lessons/:title/:id',
		name: 'User solved summary',
		component: UserSolvedSummary,
		layout: 'dashboard',
	},
	{
		path: '/lessons/solved/:title/:id',
		name: 'User solved Question',
		component: UserSolvedQuestion,
		layout: 'dashboard',
	},
	{
		path: '/lessons/detail/:id',
		name: 'Lesson Detail',
		component: LessonsDetail,
		layout: 'dashboard',
	},
	{
		path: '/statistics',
		name: 'İstatistik',
		component: Statistics,
		layout: 'dashboard',
	},
	{
		path: '/digerleri-ne-yapiyor',
		name: 'Diğerleri Ne Yapıyor',
		component: WhatOthersDo,
		layout: 'dashboard',
	},
	{
		path: '/test-istatistiks/:id',
		name: 'Diğerleri Ne Yapıyor',
		component: TestIstatistiks,
		layout: 'dashboard',
	},
	{
		path: '/gorevlerim',
		name: 'Plan',
		component: MyDuties,
		layout: 'dashboard',
	},
	{
		path: '/yayinci-tercihlerim',
		name: 'Favori Yayınevleri',
		component: Publisher,
		layout: 'dashboard',
	},
	{
		path: '/profilim/:tabId?',
		name: 'Profilim',
		component: MyProfile,
		layout: 'dashboard',
	},
	{
		path: '/profilim/sifre-degistir',
		name: 'Şifreni Değiştir',
		component: ResetPassword,
		layout: 'dashboard',
	},
	{
		path: '/arkadaslarini-davet-et-kazan',
		name: 'Arkadaşlarını davet et',
		component: InviteYourFriends,
		layout: 'dashboard',
	},
	{
		path: '/test/:id',
		name: 'Test',
		component: Test,
		layout: 'dashboard',
	},
	{
		path: '/pdf-test-uret/:courseId/:subTopicId',
		name: 'Pdf Test Üret',
		component: PdfIntroduction,
		layout: 'dashboard',
	},
	{
		path: '/pdf-cevap-gonder/:code',
		name: 'Pdf Cevap Gönder',
		component: PdfSendAnswers,
		layout: 'dashboard',
	},
	{
		path: '/kisa-konu-ozeti/:id',
		name: 'Kuno Karti',
		component: FlashCard,
		layout: 'dashboard',
	},
	{
		path: '/test-sonucu/:id',
		name: 'Test Result',
		component: TestResult,
		layout: 'dashboard',
	},
	{
		path: '/ktt-test-sonucu/:id/:course',
		name: 'KTT Test Result',
		component: KttTestResult,
		layout: 'dashboard',
	},
	{
		path: '/deneme-test-sonucu/:trialExamId/:title',
		name: 'Trial Test Result',
		component: TrialTestResult,
		layout: 'dashboard',
	},
	{
		path: '/deneme-analizi/:trialExamId/:testId/:title/:testSection/:fromTrialResult/:fromTrialDetail',
		name: 'Trial Analysis',
		component: TrialAnalysis,
		layout: 'dashboard',
	},
	{
		path: '/pratik-deneme-sonucu/:trialExamId/:title/:sessionType',
		name: 'Partial Trial Test Result',
		component: PartialTrialTestResult,
		layout: 'dashboard',
	},
	{
		path: '/add-from-outside/:id/:topic/:publisher/:courseTopic',
		name: 'Dışardan Ekle',
		component: AddFromOutside,
		layout: 'dashboard',
	},
	{
		path: '/pdf-reader/:url/:code',
		name: 'Pdf Reader',
		component: PdfReader,
		layout: 'dashboard',
	},
	{
		path: '/test-information/:id',
		name: 'Soru Detayı',
		component: TestInformation,
		layout: 'dashboard',
	},
	{
		path: '/cekilisler',
		name: 'Çekiliş Haklarım',
		component: Lottery,
		layout: 'dashboard',
	},
	{
		path: '/test-credit',
		name: 'Test Credit',
		component: TestCredit,
		layout: 'dashboard',
	},
	{
		path: '/simulator',
		name: 'Simulator',
		component: Simulator,
		layout: 'dashboard',
	},
	{
		path: '/simulator-form',
		name: 'Simulator Form',
		component: SimulatorForm,
		layout: 'dashboard',
	},
	{
		path: '/uyelik-bilgileri',
		name: 'Üyelik Bilgilerini Görüntüle',
		component: ViewInformation,
		layout: 'dashboard',
	},
	{
		path: '/logout',
		name: 'Cikis Yap',
		component: Logout,
		layout: 'dashboard',
	},
	{
		path: '/onlineLecture',
		name: 'Canli Dersler',
		component: onlineLecture,
		layout: 'dashboard',
	},
	{
		path: '/coaching',
		name: 'Öğrenci Koçluğu',
		component: Coaching,
		layout: 'dashboard',
	},
	{
		path: '/whatIsCoaching',
		name: 'Koçluk Sistemi Nasıl Çalışır',
		component: WhatIsCoaching,
		layout: 'dashboard',
	},
	{
		path: '/onlineLecture/tumunu-izle',
		name: 'Tum Canli Dersler',
		component: watchAll,
		layout: 'dashboard',
	},
	{
		path: '/lessons/videoLesson',
		name: 'Video Lesson',
		component: videoLesson,
		layout: 'dashboard',
	},
	{
		path: '/topicScanTest',
		name: 'Topic Scan Test',
		component: TopicScanTest,
		layout: 'dashboard',
	},
	{
		path: '/schoolPanel',
		name: 'School Panel',
		component: schoolPanel,
		layout: 'dashboard',
	},
	{
		path: '/schoolPanel/lectures',
		name: 'SC Lessons',
		component: schoolPanelLectures,
		layout: 'dashboard',
	},
	{
		path: '/schoolPanel/statistics',
		name: 'SP Statistics',
		component: schoolPanelStatistics,
		layout: 'dashboard',
	},
	{
		path: '/PakodemyKoc',
		name: 'Chat Gpt Summary',
		component: ChatGpt,
		layout: 'dashboard',
	},
	{
		path: '/deneme-pdf-cevap-gonder/:code',
		name: 'Deneme Pdf Cevap Gönder',
		component: TrialPdfSendAnswers,
		layout: 'dashboard',
	},
	{
		path: '/scoreCalculation',
		name: 'Tercih Simülatörü',
		component: ScoreCalculation,
		layout: 'dashboard',
	},
	{
		path: '/universities',
		name: 'Tercih Simülatörü Üniversiteler',
		component: Universities,
		layout: 'dashboard',
	},
	{
		path: '/myPrefenceList',
		name: 'Tercih Simülatörü Tercih Listem',
		component: MyPreferenceList,
		layout: 'dashboard',
	},
	{
		path: '/myResults',
		name: 'Tercih Simülatörü Sonuçlarım',
		component: MyResults,
		layout: 'dashboard',
	},
	{
		path: '/kurumsal-kod',
		name: 'Corporate Code',
		component: CorporateCode,
		layout: 'dashboard',
	},
	{
		path: '/pdf-kod',
		name: 'Pdf kod',
		component: PdfCodeInput,
		layout: 'dashboard',
	},
	{
		path: '/yks-journey',
		name: 'YKS Yolculuğum',
		component: YKSJourney,
		layout: 'dashboard',
	},
	{
		path:'/topics/:id',
		name:'Konular',
		component:Topics,
		layout:'dashboard'
	},
	{
		name: 'Çevrimiçi Denemeler',
		path: '/online-trial-exams/:id',
		component: OnlineTrialExams,
		layout: 'dashboard',
	},
	{
		path:'/introduction',
		name:'Tanıtım',
		component: Introduction,
		layout:'public'
	},
	{
		name: 'Çevrimiçi Denemeler',
		path: '/online-trial-exams/:id',
		component: OnlineTrialExams,
		layout: 'dashboard',
	},
	{
		path:'/introduction',
		name:'Tanıtım',
		component: Introduction,
		layout:'public'
	},
	{
		path: '/studyHistory',
		name: 'Calısma Gecmisim',
		component: StudyHistory,
		layout: 'dashboard',
	},
	{
		path: '/schoolPanel/homeworks',
		name: 'SP Homeworks',
		component: SchoolPanelHomeworks,
		layout: 'dashboard',
	},
	{
		path: '/schoolPanel/homeworkInfo/:id/:homeworkType',
		name: 'SP Homework Info',
		component: SchoolPanelHomeworkInfo,
		layout: 'dashboard',
	},
	{
		path: '/achievement-test',
		name: 'Achievement Test',
		component: AchievementTest,
		layout: 'dashboard',
	},
];
export default privateRoute;
